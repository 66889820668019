import { useHistory, useParams } from 'react-router';
import type { ObjectID } from '../../types';
import { useAnamnesisState } from './AnamnesisStore';
import type { StoredCart } from '../../types/mapBookingJSONToObject';
import { GET_MULTIPLE_BOOKING_DETAILS_QUERY } from '../../../infrastructure/adapters/graphql/BookingDetailsAdapter';
import { useQuery } from '@apollo/client';

const AnamnesisFromLink = () => {
  const { shoppingCartId, token } = useParams<{
    shoppingCartId: ObjectID;
    token: ObjectID;
  }>();

  const { setToken, setClientData, setShoppingCartId } = useAnamnesisState();

  const history = useHistory();

 useQuery<{ details: StoredCart }>(
    GET_MULTIPLE_BOOKING_DETAILS_QUERY,
    {
      variables: {
        id: shoppingCartId,
        token,
      },
      onCompleted: (data) => {
        const bookingDetails = data.details;
        setToken(bookingDetails.token);
        setShoppingCartId(bookingDetails.id);
        setClientData({
          // TODO update types
          clientEmail: bookingDetails.clientData?.email || '',
          clientId: bookingDetails.client || '',
          lastShoppingCartToken: bookingDetails.token,
        });
        history.replace('/anamnesis', { direction: 'forward' });
      },
      onError: () => {
        // TODO handle error - sentry
      },
    },
  );


  return (
    <></>
  )

}

export default  AnamnesisFromLink