import SubPageLayout from '../../../ui/layout/SubPageLayout';
import type IBookingDetailsAdapter from '../BookingDetails/IBookingDetailsAdapter';
import injectables from '../../injectables';
import { useInject } from 'inversify-hooks';
import { IonCol, IonGrid, IonRow, IonSpinner, useIonViewWillEnter } from '@ionic/react';
import { useContextTranslation } from '../../../ui/translation';
import CartSummaryTable from '../../../ui/elements/CartSummaryTable';
import { TFunction } from 'i18next';
import { ReactComponent as QuestionnaireIcon } from './images/questionnaireIcon.svg';
import styles from './anamnesis.module.scss';
import Button from '../../../ui/elements/Button/Button';
import { useHistory } from 'react-router';
import { useAnamnesisState } from './AnamnesisStore';
import BeforeImage from './images/beforeImg.jpg';

const anamnesisInfo = (
  t: TFunction,
): string[] => {
  return [
    t('hint_1'),
    t('hint_2'),
    t('hint_3'),
  ];
};

const NextFooter = () => {
  const history = useHistory();
  const t = useContextTranslation('page.anamnesis');

  return (
    <div className={styles.nextFooter}>
      <div className={styles.spacer}></div>
      <div className={styles.container}>
        <Button color="primary" onClick={() => {
          history.push('/anamnesis-form');
        }}>
          {t('next')}
        </Button>
      </div>
    </div>
  );
};

const ClientAnamnesisWrapper = () => {
  const { shoppingCartId, token } = useAnamnesisState();
  const history = useHistory();

  useIonViewWillEnter(() => {
    if (!token || !shoppingCartId) {
      history.replace('/bookings');
    }
  }, [token, shoppingCartId]);

  return (
    <SubPageLayout
      className={styles.anamnesisLayout}
      footerContent={NextFooter}
      beforeContent={
        <div className={styles.imageWrapper}>
          <img className={styles.image} src={BeforeImage} alt={'media'} />
        </div>
      }
    >
      {shoppingCartId && token && (
        <ClientAnamnesis shoppingCartId={shoppingCartId} token={token} />
      )}
    </SubPageLayout>

  );
};

const ClientAnamnesis = (
  {
    shoppingCartId,
    token,
  }: {
    shoppingCartId: string,
    token: string,
  }) => {
  const t = useContextTranslation('page.anamnesis');
  const mt = useContextTranslation('misc');
  //TODO remove this inject and refactor
  const [adapter] = useInject<IBookingDetailsAdapter>(
    injectables.pages.BookingDetailsAdapter,
  );

  const details = adapter.useMultipleBookingsDetails(
    shoppingCartId,
    token,
  );

  const isPaid = details.value?.status === 'paid';
  const cart = details.value;

  const expertTreatments = cart?.bookings?.map((treatment) => ({
    type: treatment.treatmentType,
    expert: treatment.expert,
  }));

  return (
    <>
      {(details.loading || (!isPaid && !details.error)) && (
        <IonGrid>
          <IonRow className="ion-justify-content-center spinner-row">
            <IonCol size="12">
              <IonSpinner color="secondary" name="crescent" />
            </IonCol>
          </IonRow>
        </IonGrid>
      )}
      {details.error && (
        <div className="error">{mt('something_went_wrong')}</div>
      )}
      {!isPaid && !details.error && (
        <div className="not-paid">{t('still_not_paid')}</div>
      )}
      {cart && expertTreatments && isPaid && (
        <IonGrid>
          <IonRow>
            <IonCol size="12" className={styles.headingWrapper}>
              <QuestionnaireIcon className={styles.questionnaireIcon} />
              <h2 className={styles.textCenter}>{t('questionnaire_heading')}</h2>
              <div className={styles.textCenter}>{t('questionnaire_subheading')}</div>
            </IonCol>
            <IonCol size="12">
              <CartSummaryTable
                time={cart.time}
                expertTreatments={expertTreatments}
                length={{
                  id: '',
                  length: cart.length,
                  price: 0,
                }}
                location={cart.address}
              />
            </IonCol>
            <IonCol size="12" className={styles.anamnesisInfo}>
              <ul className={styles.anamnesisInfoList}>
                {anamnesisInfo(
                  t,
                ).map((el) => (
                  <li className={styles.anamnesisInfoItem} key={el}>
                    <span className={styles.anamnesisInfoText}>{el}</span>
                  </li>
                ))}
              </ul>
            </IonCol>
          </IonRow>
        </IonGrid>
      )}
    </>
  );
};

export default ClientAnamnesisWrapper;
